import React from "react"
// import { navigate } from "gatsby-link"
import {
	GoogleReCaptchaProvider,
	GoogleReCaptcha,
} from "react-google-recaptcha-v3"

export default class ContactForm extends React.Component {
	constructor(props) {
		super(props)
		this.rootRef = React.createRef()
		this.state = {
			display: false,
			isValidated: false,
			recaptchaToken: null,
			status: "none",
			name: "",
			email: "",
			message: "",
		}
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value })
		this.setState({
			status:
				this.state.name && this.state.email && this.state.message
					? "sendable"
					: "none",
		})
	}

	captchaVerifyCallback = (recaptchaToken) => {
		// console.log(`captchaVerifyCallback`, recaptchaToken)
		this.setState({ recaptchaToken })
	}
	handleSubmit = (e) => {
		e.preventDefault()
		this.setState({ status: "sending" })
		const submitMessage = async () => {
			// const form = e.target
			const response = await fetch("/api/hello", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ ...this.state }),
				// headers: { "Content-Type": "application/x-www-form-urlencoded" },
				// body: encode({
				// 	"form-name": form.getAttribute("name"),
				// 	...this.state,
				// }),
			})
			const json = await response.json()
			console.log(`response`, json)
			setTimeout(() => {
				this.setState({ status: "sent", name: "", email: "", message: "" })
			}, 1000)
		}
		try {
			submitMessage()
		} catch (submitException) {
			console.log(`submitException`, submitException)
		} finally {
			return false
		}
	}

	gotGRecaptcha = (grecaptcha) => {
		grecaptcha.render()
	}
	componentDidMount() {
		const callback = (entries) => {
			const intersection = entries[0]
			if (intersection.intersectionRect.height > 0) {
				// console.log(`loading google map`)
				this.setState({ display: true })
			}
		}
		this.observer = new IntersectionObserver(callback)
		this.observer.observe(this.rootRef.current)
		setTimeout(() => {
			// console.log(`loading google map`)
			this.setState({ display: true })
		}, 5000)
	}

	render() {
		const { status, display } = this.state
		return (
			<div ref={this.rootRef} className={`ContactForm`}>
				{!display && <span>Lade Recaptcha ...</span>}
				{display && (
					<GoogleReCaptchaProvider
						reCaptchaKey={process.env.RECAPTCHA_WEBSITE_KEY}
					>
						<GoogleReCaptcha onVerify={this.captchaVerifyCallback} />
						{status === "sent" && (
							<>
								<h3>Vielen Dank für Ihre Nachricht!</h3>
								<p>
									Wir sollten Ihre Mitteilung — sofern sie nicht als
									unerwünschte Spam-Nachricht von unserem System aussortiert
									wurde — in Kürze erhalten, und werden uns alsbald bei Ihnen
									melden.{" "}
									<u>Eine Zustellung kann jedoch nicht garantiert werden.</u>
									<br />
									<br />
									Melden Sie sich <u>in dringenden Fällen</u> deswegen unbedingt
									telefonisch!
								</p>
							</>
						)}
						{status !== "sent" && (
							<>
								<div
									className={`spinner-wrapper ${
										status === "sending" ? "spinner-active" : "spinner-inactive"
									}`}
								>
									<div className="spinner"></div>
								</div>
								<form
									name="contact"
									method="post"
									action="/contact/thanks/"
									data-netlify="true"
									data-netlify-honeypot="bot-field"
									onSubmit={this.handleSubmit}
									style={{ opacity: status === "sending" ? 0.2 : 1 }}
									// style={{visibility: status === 'sending' ? 'hidden' : 'visible'}}
								>
									{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
									<input type="hidden" name="form-name" value="contact" />
									<div hidden>
										<label htmlFor={"bot-field"}>
											Dieses Feld bitte nicht ausfüllen
										</label>
										<input name="bot-field" onChange={this.handleChange} />
									</div>
									<div className="field">
										<label className="label" htmlFor={"name"}>
											Ihr Name
										</label>
										<div className="control">
											<input
												className="input"
												disabled={status === "sending"}
												type={"text"}
												name={"name"}
												value={this.state.name}
												onChange={this.handleChange}
												id={"name"}
												required={true}
												placeholder="Ihr Name"
											/>
										</div>
									</div>
									<div className="field">
										<label className="label" htmlFor={"email"}>
											Ihre E-Mail-Adresse
										</label>
										<div className="control">
											<input
												className="input"
												disabled={status === "sending"}
												type={"email"}
												name={"email"}
												value={this.state.email}
												onChange={this.handleChange}
												id={"email"}
												required={true}
												placeholder="Ihre E-Mail-Adresse"
											/>
										</div>
									</div>
									<div className="field">
										<label className="label" htmlFor={"message"}>
											Ihre Nachricht
										</label>
										<div className="control">
											<textarea
												disabled={status === "sending"}
												className="textarea"
												name={"message"}
												value={this.state.message}
												onChange={this.handleChange}
												id={"message"}
												required={true}
												placeholder="Ihre Nachricht"
											/>
										</div>
									</div>
									<div className="grecaptcha-inline-message">
										Diese Seite wird durch reCAPTCHA geschützt und es gelten die
										Google-
										<a href="https://policies.google.com/privacy">
											Datenschutzerklärungen
										</a>{" "}
										und{" "}
										<a href="https://policies.google.com/terms">
											-Nutzungsbedingungen
										</a>
									</div>
									<div className="field">
										<button
											className="button"
											type="submit"
											disabled={status !== "sendable"}
										>
											Abschicken
										</button>
									</div>
								</form>
							</>
						)}
					</GoogleReCaptchaProvider>
				)}
			</div>
		)
	}
}

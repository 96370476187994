import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"

import { Map, Marker, InfoWindow, GoogleApiWrapper } from "google-maps-react"

const styles = [
	{
		featureType: "all",
		elementType: "geometry",
		stylers: [
			{
				color: "#f5f1f1",
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text.fill",
		stylers: [
			{
				gamma: 0.01,
			},
			{
				lightness: 20,
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.text.stroke",
		stylers: [
			{
				saturation: -31,
			},
			{
				lightness: -33,
			},
			{
				weight: 2,
			},
			{
				gamma: 0.8,
			},
		],
	},
	{
		featureType: "all",
		elementType: "labels.icon",
		stylers: [
			{
				visibility: "off",
			},
		],
	},
	{
		featureType: "administrative",
		elementType: "labels.text.fill",
		stylers: [
			{
				saturation: "0",
			},
			{
				visibility: "on",
			},
			{
				color: "#8cb776",
			},
		],
	},
	{
		featureType: "administrative",
		elementType: "labels.text.stroke",
		stylers: [
			{
				visibility: "on",
			},
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "landscape",
		elementType: "geometry",
		stylers: [
			{
				color: "#f9f6f3",
			},
			{
				lightness: "66",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "geometry",
		stylers: [
			{
				saturation: 20,
			},
		],
	},
	{
		featureType: "poi",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#8cb776",
			},
		],
	},
	{
		featureType: "poi",
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#ffffff",
			},
		],
	},
	{
		featureType: "poi.park",
		elementType: "geometry",
		stylers: [
			{
				lightness: 20,
			},
			{
				saturation: -20,
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry",
		stylers: [
			{
				lightness: 10,
			},
			{
				saturation: -30,
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry.fill",
		stylers: [
			{
				lightness: "-2",
			},
			{
				saturation: "-10",
			},
			{
				visibility: "on",
			},
			{
				gamma: "1",
			},
		],
	},
	{
		featureType: "road",
		elementType: "geometry.stroke",
		stylers: [
			{
				saturation: 25,
			},
			{
				lightness: 25,
			},
		],
	},
	{
		featureType: "road",
		elementType: "labels.text",
		stylers: [
			{
				saturation: "-4",
			},
			{
				lightness: "35",
			},
			{
				color: "#9ca199",
			},
		],
	},
	{
		featureType: "road",
		elementType: "labels.text.stroke",
		stylers: [
			{
				visibility: "on",
			},
			{
				color: "#fcfcfc",
			},
		],
	},
	{
		featureType: "water",
		elementType: "all",
		stylers: [
			{
				lightness: -20,
			},
		],
	},
	{
		featureType: "water",
		elementType: "geometry.fill",
		stylers: [
			{
				saturation: "18",
			},
			{
				lightness: "53",
			},
		],
	},
	{
		featureType: "water",
		elementType: "labels.text.fill",
		stylers: [
			{
				color: "#bc898d",
			},
		],
	},
	{
		featureType: "water",
		elementType: "labels.text.stroke",
		stylers: [
			{
				color: "#ffffff",
			},
			{
				visibility: "on",
			},
		],
	},
]
class MapSection extends React.Component {
	state = {
		showingInfoWindow: false,
		activeMarker: {},
		google: null,
	}
	constructor(props) {
		super(props)
		this.rootRef = React.createRef()
	}
	componentDidMount() {
		const callback = (entries) => {
			const intersection = entries[0]
			if (!this.state.google && intersection.intersectionRect.height > 0) {
				// console.log(`loading google map`)
				this.setState({ google: this.props.google })
			}
		}
		this.observer = new IntersectionObserver(callback)
		this.observer.observe(this.rootRef.current)
		setTimeout(() => {
			// console.log(`loading google map`)
			if (!this.state.google) this.setState({ google: this.props.google })
		}, 5000)
	}
	onMarkerClick = (props, marker, e) =>
		this.setState({
			activeMarker: marker,
			showingInfoWindow: true,
		})
	render() {
		const { siteName, street, zipcode, city, location } = this.props.data
		const { google, activeMarker, showingInfoWindow } = this.state

		let geo
		try {
			geo = JSON.parse(location)
		} catch (JSONparseException) {
			console.warn(`JSONparseException`, JSONparseException)
			geo = null
		}
		const [lng, lat] = geo && geo.coordinates ? geo.coordinates : [null, null]

		return (
			<section ref={this.rootRef} className="section section-map">
				<div style={{ height: "100vh", width: "100%" }}>
					{(!google) && <span>Lade Karte...</span>}
					{google && lat && lng && (
						<Map
							google={google}
							zoom={14}
							initialCenter={{ lat, lng }}
							styles={styles}
						>
							<Marker
								onClick={this.onMarkerClick}
								name={siteName}
								position={{ lat, lng }}
								icon={{
									url: "/img/icons/marker.png",
									anchor: new google.maps.Point(18, 62),
									scaledSize: new google.maps.Size(36, 62),
								}}
							></Marker>
							<InfoWindow
								marker={activeMarker}
								visible={showingInfoWindow}
							>
								<div className="map-info-window">
									<h3>{siteName}</h3>
									<p>
										{street}
										<br />
										{zipcode}&nbsp;{city}
									</p>
								</div>
							</InfoWindow>
						</Map>
					)}
				</div>
			</section>
		)
	}
}

MapSection.propTypes = {
	data: PropTypes.shape({}),
}

const WrappedMapSection = GoogleApiWrapper((props) => ({
	apiKey: props.data.googlemapsAPIkey,
}))(MapSection)

export default () => (
	<StaticQuery
		query={graphql`
			query MapSectionQuery {
				stringsYaml {
					googlemapsAPIkey
					siteName
				}
				contactinfoYaml {
					location
					street
					zipcode
					city
				}
			}
		`}
		render={({ stringsYaml, contactinfoYaml }) => (
			<WrappedMapSection data={{ ...stringsYaml, ...contactinfoYaml }} />
		)}
	/>
)

import React from "react";
export function InertAnchor({ children, href, ...props }) {
	const activate = (evt) => {
		evt.currentTarget.setAttribute("href", href);
	};
	return (
		<a
			onMouseOver={activate}
			onTouchStart={activate}
			onFocus={activate}
			role="link"
			tabIndex={0}
			{...props}
		>
			{children}
		</a>
	)
}

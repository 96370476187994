import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import { InertAnchor } from "./InertAnchor"

import IconEnvelope from "../img/icons/envelope.svg"
import IconPhone from "../img/icons/phone.svg"
import IconMap from "../img/icons/map.svg"

class ContactWhitebox extends React.Component {
	render() {
		const { street, city, email, zipcode, phone, location } = this.props.data

		const cleanPhoneRegexp = new RegExp("[-\\s\\/]+", "g")
		const cleanPhoneNumber = phone
			? phone.replace(cleanPhoneRegexp, "")
			: null
		let geo
		try {
			geo = JSON.parse(location)
		} catch (JSONparseException) {
			console.warn(`JSONparseException`, JSONparseException)
			geo = null
		}
		const [lng, lat] = geo && geo.coordinates ? geo.coordinates : [null, null]
		

		return (
			<div className="whitepanel">
				<div className="whitepanel-content contact-whitepanel">
					<div className="contact-boxtitle is-size-6 title-font">
						So erreichen Sie uns
					</div>
					{phone && cleanPhoneNumber && (
						<InertAnchor
							href={`tel:${cleanPhoneNumber}`}
							className="whitepanel-section contact-phone"
						>
							<div className="is-size-6 contact-key">Telefon</div>
							<IconPhone className="is-size-6 contact-icon" />
							<div className="is-size-6 contact-value">{phone}</div>
						</InertAnchor>
					)}
					{lat && lng && street && zipcode && city && (
						<InertAnchor
							href={`geo:${lat},${lng}`}
							className="whitepanel-section contact-address"
						>
							<div className="is-size-6 contact-key">Adresse</div>
							<IconMap className="is-size-6 contact-icon" />
							<div className="is-size-6 contact-value">
								{street} &ndash; {zipcode} {city}
							</div>
						</InertAnchor>
					)}
					{email && (
						<InertAnchor
							href={`mailto:${email}`}
							className="whitepanel-section contact-email"
						>
							<div className="is-size-6 contact-key">E-Mail</div>
							<IconEnvelope className="is-size-6 contact-icon" />
							<div className="is-size-6 contact-value">{email}</div>
						</InertAnchor>
					)}
				</div>
			</div>
		)
	}
}

ContactWhitebox.propTypes = {
	data: PropTypes.shape({}),
}

export default () => (
	<StaticQuery
		query={graphql`
			query ContactWhiteboxQuery {
				contactinfoYaml {
					street
					zipcode
					city
					email
					location
					phone
				}
			}
		`}
		render={({ contactinfoYaml }) => (
			<ContactWhitebox data={{ ...contactinfoYaml }} />
		)}
	/>
)

import React from 'react'
import PropTypes from 'prop-types'
// import PreviewCompatibleImage from './PreviewCompatibleImage'

const AffectionsGrid = ({ areaItems }) => (
	<div className="affections-grid columns is-multiline">
		{areaItems.map(({title, image, text}) => {
			return (
				<div key={title} className="affections-area column is-6">
					<section className="section whitepanel">
						<div className="has-text-centered">
							<div className="affection-area-icon">
								{/* <PreviewCompatibleImage imageInfo={area} /> */}
								<img
									className="margin-top-0 hero-image"
									alt={`Icon für ${title}`}
									width="76"
									height="76"
									src={
										!!image && !!image.childImageSharp
											? image.childImageSharp.fluid.src
											: image.publicURL
									}
								></img>
							</div>
						</div>
						<h4>{title}</h4>
						<p>{text}</p>
					</section>
				</div>
			)})}
	</div>
)

AffectionsGrid.propTypes = {
  areaItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

export default AffectionsGrid

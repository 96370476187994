import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import ContactForm from "./ContactForm"
import { InertAnchor } from "./InertAnchor"

class ContactSection extends React.Component {
	render() {
		const { street, city, email, zipcode, phone, location } = this.props.data

		const cleanPhoneRegexp = new RegExp("[-\\s\\/]+", "g")
		const cleanPhoneNumber = phone
			? phone.replace(cleanPhoneRegexp, "")
			: null
		let geo
		try {
			geo = JSON.parse(location)
		} catch (JSONparseException) {
			console.warn(`JSONparseException`, JSONparseException)
			geo = null
		}
		const [lng, lat] = geo && geo.coordinates ? geo.coordinates : [null, null]

		return (
			<section className="section section-contact">
				<div className="container">
					<h3 className="is-size-2 title">So erreichen Sie uns</h3>
					<div className="columns">
						<div className="column is-4">
							{phone && cleanPhoneNumber && (
								<InertAnchor
									href={`tel:${cleanPhoneNumber}`}
									className="data-section contact-phone"
								>
									<div className="contact-key">Telefon</div>
									<div className="contact-value">{phone}</div>
								</InertAnchor>
							)}
							{lat && lng && street && zipcode && city && (
								<InertAnchor
									href={`geo:${lat},${lng}`}
									className="data-section contact-address"
								>
									<div className="contact-key">Adresse</div>
									<div className="contact-value">
										{street} &ndash; {zipcode} {city}
									</div>
								</InertAnchor>
							)}
							{email && (
								<InertAnchor
									href={`mailto:${email}`}
									className="data-section contact-email"
								>
									<div className="contact-key">E-Mail</div>
									<div className="contact-value">{email}</div>
								</InertAnchor>
							)}
						</div>
						<div className="column is-8">
							<ContactForm />
						</div>
					</div>
				</div>
			</section>
		)
	}
}

ContactSection.propTypes = {
	data: PropTypes.shape({}),
}

export default () => (
	<StaticQuery
		query={graphql`
			query ContactSectionQuery {
				contactinfoYaml {
					street
					zipcode
					city
					email
					location
					phone
				}
			}
		`}
		render={({ contactinfoYaml }) => (
			<ContactSection data={{ ...contactinfoYaml }} />
		)}
	/>
)

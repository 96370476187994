import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import AffectionsGrid from "../components/affectionsGrid"
import VideoSection from "../components/videoSection"
import ContactSection from "../components/contactSection"
import MapSection from "../components/mapSection"
import ContactWhitebox from "../components/contactWhitebox"


export const IndexPageTemplate = ({
					title,
					image,
					herovideo,
					intro,
					affections,
				}) => {

					return (
						<div>
							<VideoSection herovideo={herovideo} heroimage={image} />

							<div className="container">
								<ContactWhitebox />
							</div>

							<section className="section">
								<div className="container">
									<div className="columns">
										<div className="column">
											<div className="content">
												<div className="columns">
													<div className="column is-12">
														<h3 className="is-size-2 title">{intro.heading}</h3>
														<div className="is-size-6 subtitle">
															{intro.subheading}
														</div>
														<div
															dangerouslySetInnerHTML={{
																__html: intro.introtext,
															}}
														/>

														{intro.signature && (
															<img
																className="signature-image"
																alt="Unterschrift"
																height="48"
																width="226"
																src={
																	!!intro.signature.childImageSharp
																		? intro.signature.childImageSharp.fluid.src
																		: intro.signature
																}
															></img>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className="section">
								<div className="container">
									<div className="columns">
										<div className="column">
											<div className="content">
												<div className="columns">
													<div className="column is-12">
														<h3 className="is-size-2 title">
															{affections.heading}
														</h3>
														<p>{affections.description}</p>
														<h3 className="is-size-2 title">
															{affections.areasheading}
														</h3>
														<AffectionsGrid areaItems={affections.areas} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<ContactSection />
							<MapSection />
						</div>
					)
				}

IndexPageTemplate.propTypes = {
	title: PropTypes.string,
	herovideo: PropTypes.string,
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	intro: PropTypes.shape({
		signature: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		heading: PropTypes.string,
		subheading: PropTypes.string,
		introtext: PropTypes.string,
	}),
	affections: PropTypes.shape({
		areas: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string,
				text: PropTypes.string,
				image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
			})
		),
	}),
}

const IndexPage = ({ data }) => {
	return (
		<Layout>
			<IndexPageTemplate {...data.markdownRemark.frontmatter} />
		</Layout>
	)
	// return (
	// 	<Layout>
	// 		<IndexPageTemplate
	// 			image={frontmatter.image}
	// 			signature={frontmatter.signature}
	// 			title={frontmatter.title}
	// 			heading={frontmatter.heading}
	// 			subheading={frontmatter.subheading}
	// 			intro={frontmatter.intro}
	// 			phone={frontmatter.phone}
	// 			contact.address={frontmatter.contact.address}
	// 			contact.geo={frontmatter.contact.geo}
	// 			contact.email={frontmatter.contact.email}
	// 			affections={frontmatter.affections}
	// 		/>
	// 	</Layout>
	// )
}

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
}

export default IndexPage

export const pageQuery = graphql`
					query IndexPageTemplate {
						markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
							frontmatter {
								title
								image {
									childImageSharp {
										fluid(maxWidth: 1200, quality: 92) {
											...GatsbyImageSharpFluid_withWebp
										}
									}
									publicURL
								}
								herovideo
								intro {
									heading
									subheading
									introtext
									signature {
										childImageSharp {
											fluid(maxWidth: 400, quality: 92) {
												...GatsbyImageSharpFluid
											}
										}
										publicURL
									}
								}
								affections {
									areas {
										image {
											childImageSharp {
												fluid(maxWidth: 300, quality: 92) {
													...GatsbyImageSharpFluid
												}
											}
											extension
											publicURL
										}
										title
										text
									}
									heading
									description
									areasheading
								}
							}
						}
					}
				`
